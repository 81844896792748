<template>
  <div id="app" data-app>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-data-table hide-default-footer
                        :headers="headers"
                        :items="items"
                        :single-select="true"
                        class="elevation-1"
                        show-select
                        item-key="formId"
                        v-model="selected"
          >
            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Формы</v-toolbar-title>
                <v-spacer/>
                <v-btn @click="saveData()">Сохранить</v-btn>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Новая форма
                    </v-card-title>
                    <v-card-text>
                      <v-text-field :rules="[rules.isNum]" v-model="addForm" label="Id формы"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn :disabled="!rules.isNum(addForm)||rules.isNum(addForm)==='Введите число.'" @click="items.push({formId: addForm, items: []});addForm='';dialog=false">Добавить</v-btn>
                      <v-spacer/>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <!--            eslint-disable-next-line-->
            <template v-slot:item.action="{item}">
              <v-btn icon @click="deleteForm(item)">
                <v-icon
                    small
                >
                  mdi-delete
                </v-icon>
              </v-btn>

            </template>
            <template v-slot:no-data>
              Тут ничего нет
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <v-row style="margin-top: 25px" v-if="selected.length<1">
            <v-spacer/>
            Выберите или создайте форму
            <v-spacer/>
          </v-row>
          <div v-if="selected.length>0">
            <v-toolbar flat>
              <v-toolbar-title>Форма {{ selected[0].formId }}</v-toolbar-title>
              <v-spacer/>
              <v-btn icon @click="selected[0].items.push({step: '', listId: ''})">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row v-for="item in selected[0].items" :key="selected[0].items.indexOf(item)">
              <v-col>
                <v-text-field :rules="[rules.isNum]"  label="Шаг маршрутизации" v-model="item.step"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :rules="[rules.isNum]" label="Id списка" v-model="item.listId"></v-text-field>
              </v-col>
              <v-col cols="2" style="align-items: center;justify-content: center; display: flex">
                <v-btn icon @click="selected[0].items.splice(selected[0].items.indexOf(item), 1)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    items: [
    ],
    headers: [
      {text: 'Id формы', value: 'formId'},
      {text: 'Действия', value: 'action', sortable: false}
    ],
    dialog: false,
    selected: [],
    addForm:'',
    rules:{
      isNum: value => {
        const pattern = /^\d+$/
        return pattern.test(value) || 'Введите число.'
      },
    }
  }),
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.axios.get("http://localhost/getData").then(resp=>{
        this.items=resp.data
      })
    },
    saveData(){
      this.axios.post("http://localhost/pushData",this.items)
    },
    logging(item) {
      console.log(item)
    },
    deleteForm(item){
      if(this.selected[0].formId===item.formId){
        this.selected = []
      }
      this.items.splice(this.items.findIndex(e=>e.formId===item.formId),1);
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin-top: 60px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  text-align: center;
}
</style>
